<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h3 class="sr-only">{{$t('photography.gear.header')}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm">
                <h4>{{$t('photography.gear.cameras')}}</h4>
                <ul>
                    <li>Nikon EM (2)</li>
                    <li>Nikon FM</li>
                    <li>Nikon F4</li>
                    <li>Nikon D7000</li>
                    <li>Nikon D700</li>
                    <li>Nikon Z6</li>
                    <li>Nikon Z50</li>
                    <li>Fujica GW690</li>
                    <li>Yashica Electro</li>
                    <li>Yashica-44</li>
                    <li>Yashica-Mat</li>
                </ul>
            </div>
            <div class="col-12 col-sm">
                <h4>{{$t('photography.gear.lenses')}}</h4>
                <ul>
                <li>Nikkor 28mm f2.8 AI-s</li>
                <li>Nikkor 35mm f2 AF-D</li>
                <li>Nikkor 35mm f1.8G AF-S DX</li>
                <li>Nikon Series E 50mm f1.8</li>
                <li>Micro-Nikkor 55mm f2.8 AI-s</li>
                <li>Helios 44-2</li>
                <li>Nikon Series E 100mm f2.8</li>
                <li>Nikon Series E 70-210mm f4</li>
                <li>Nikkor AF-D 70-210mm f/4-5.6</li>
                <li>Nikkor Z DX 16-50mm f/3.5-6.3 VR</li>
                <li>Nikkor Z DX 50-250mm f/4.5-6.3 VR</li>
                <li>Nikkor Z 24-70mm f/2.8 S</li>
                </ul>
            </div>
            <div class="col-12 col-sm">
                <h4>Misc</h4>
                <ul>
                    <li>Nikon SB-24</li>
                    <li>Neewer Speedlite 750II</li>
                    <li>Scanner: Epson V550, plustek 8100</li>
                    <li>Current Developer: Illfosol 3</li>
                    <li>Current Fixer: Illford Rapid Fixer</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm">
                <h4>{{$t('photography.gear.favouriteFilms')}}</h4>
                <ul>
                <li>Kodak TMax 400</li>
                    <li>Fujifilm Acros 100 (being discontinued) <i class="far fa-frown"></i></li>
                    <li>Kentmere 100 & 400</li>
                    <li>Illford FP4+</li>
                    <li>Kodak Porta 400</li>
                    <li>Kodak Ektar</li>
                    <li>CineStill 800T</li>
                    <li>Fujifilm Natura 1600 (being discontinued) <i class="far fa-frown"></i></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Gear'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
