<template>
    <div class="row">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-8">
                    <h3>Photography Projects</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-8">
                    <h4>Current Projects</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-8">
                    <ul>
                        <li>Be a better photographer project: ongoing</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Projects'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
